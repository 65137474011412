<template>
  <div>
    <app-cells position="start">
      <router-link  :to="{ name: 'directorate-create' }" class="btn btn--link btn--link-icon">
        <span>Добавить службу</span>
      </router-link>
    </app-cells>
    <app-table-head
      v-if="category"
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
    />
    <app-cells position="start">
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по ФИО или ID"
        class="select select--minw"
      >
      </v-select>
      <v-select
        :options="category_options"
        :filterable="false"
        :clearable="false"
        :searchable="false"
        v-model="category"
        @input="onChangeCategory"
        label="name"
        placeholder="Выберите категорию"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
      <v-select
        v-if="category.has_service"
        :reduce="item => item.id"
        :options="service_options"
        :filterable="false"
        :clearable="false"
        :searchable="false"
        v-model="service"
        @input="onChangeService"
        label="name"
        placeholder="Выберите службу"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
      <v-select
        v-if="category"
        :reduce="item => item.code"
        :options="status_options"
        :filterable="false"
        :clearable="true"
        :searchable="false"
        v-model="status_code"
        @input="onChangeStatus"
        label="name"
        placeholder="Выберите статус"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
    </app-cells>
    <v-client-table
      v-if="table.items.length"
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="last_name" slot-scope="props" class="table-default__left">
        {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic ? props.row.patronymic : '' }}
      </div>
<!--      <template #last_name="props">-->
<!--        {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic ? props.row.patronymic : '' }}-->
<!--      </template>-->
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
  </div>
</template>

<script>
import {
  getDirectoratesCategories,
  getStatus,
  getDirectoratesServices,
  getDirectoratesList
} from '@/http'
import {debounce} from 'lodash'

export default {
  name: 'DirectorateList',
  data() {
    return {
      table: {
        items: [],
        columns: ['pk', 'last_name', 'direction_category.name', 'service.name', 'status.name'],
        options: {
          headings: {
            'pk': 'ID',
            'last_name': 'ФИО',
            'direction_category.name': 'Категория',
            'service.name': 'Служба',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одной службы',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      status_options: [],
      category_options: [],
      service_options: [],
      status_code: '',
      category: '',
      service: '',
    }
  },
  created() {
    getDirectoratesCategories()
      .then(response => {
        this.category_options = response.data
      })
    getStatus()
      .then(response => {
        this.status_options = response.data
      })
  },
  methods: {
    fetchItems(page = this.pagination.page, page_size = this.table_limit, status__code = this.status_code, direction_category__id = this.category.id, service = this.service, search = '') {
      getDirectoratesList({page, page_size, status__code, direction_category__id, service, search})
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, vm.status_code, vm.category.id, vm.service, search)
      loading(false)
    }, 350),
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems()
    },
    onClickPagination(page) {
      this.pagination.page = page
      this.fetchItems()
    },
    onChangeStatus() {
      this.fetchItems()
    },
    onChangeCategory(category) {
      this.service = ''
      if (category.has_service) {
        getDirectoratesServices(category.id)
          .then(response => {
            this.service_options = response.data
          })
      }
      this.fetchItems()
    },
    onChangeService() {
      this.fetchItems()
    },
    onRowClick(row) {
      const routeData = this.$router.resolve({name: 'directorate-detail', params: {id: row.row.pk}})
      window.open(routeData.href, '_blank')
    },
  }
}
</script>

